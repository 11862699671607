import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Layout from '../components/layout/layout';
import Container from '../components/common/container';
import PartnerModal from '../components/referral/partner_modal';

const PartnershipBenefits = () => (
  <div className="grid md:grid-cols-3 gap-8">
    {[
      {
        icon: "💰",
        title: "25% Commission",
        description: "Earn 25% of your referral's monthly subscription for their first 6 months"
      },
      {
        icon: "🤝",
        title: "No Cap Limit",
        description: "Refer as many businesses as you want - there's no limit to your earning potential"
      },
      {
        icon: "⚡",
        title: "Quick Payouts",
        description: "Get paid monthly for all active referrals within your commission period"
      }
    ].map((benefit, index) => (
      <motion.div
        key={index}
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: index * 0.1 }}
        className="bg-white p-6 rounded-xl border border-gray-100 hover:border-primary/20 transition-colors duration-200"
      >
        <span className="text-4xl mb-4 block">{benefit.icon}</span>
        <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
        <p className="text-gray-600">{benefit.description}</p>
      </motion.div>
    ))}
  </div>
);

const CommissionExample = () => {
  const plans = [
    { name: "Basic", price: 1000, commission: 250 },
    { name: "Startup", price: 5000, commission: 1250 },
    { name: "Growth", price: 15000, commission: 3750 },
    { name: "Corporate", price: 70000, commission: 17500 }
  ];

  return (
    <div className="overflow-x-auto">
      <table className="w-full">
        <thead>
          <tr className="border-b border-gray-200">
            <th className="py-4 px-6 text-left">Plan</th>
            <th className="py-4 px-6 text-right">Monthly Price (KES)</th>
            <th className="py-4 px-6 text-right">Monthly Commission (KES)</th>
            <th className="py-4 px-6 text-right">6-Month Total (KES)</th>
          </tr>
        </thead>
        <tbody>
          {plans.map((plan, index) => (
            <tr key={index} className="border-b border-gray-100">
              <td className="py-4 px-6 font-medium">{plan.name}</td>
              <td className="py-4 px-6 text-right">{plan.price.toLocaleString()}</td>
              <td className="py-4 px-6 text-right text-primary font-medium">
                {plan.commission.toLocaleString()}
              </td>
              <td className="py-4 px-6 text-right font-medium">
                {(plan.commission * 6).toLocaleString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const HowItWorks = () => (
  <div className="grid md:grid-cols-4 gap-8">
    {[
      {
        step: "1",
        title: "Sign Up",
        description: "Register as a WymBee partner to get your unique referral code"
      },
      {
        step: "2",
        title: "Refer Businesses",
        description: "Share your code with potential clients who could benefit from our platform"
      },
      {
        step: "3",
        title: "They Subscribe",
        description: "When your referrals sign up for any paid plan, you start earning"
      },
      {
        step: "4",
        title: "Earn Commission",
        description: "Get 25% of their subscription fee for 6 months, paid monthly"
      }
    ].map((step, index) => (
      <motion.div
        key={index}
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: index * 0.1 }}
        className="relative"
      >
        <div className="bg-primary/5 rounded-full w-12 h-12 flex items-center justify-center mb-4">
          <span className="text-primary font-bold">{step.step}</span>
        </div>
        <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
        <p className="text-gray-600">{step.description}</p>
        {index < 3 && (
          <svg className="hidden md:block absolute top-6 right-0 w-24 h-6 text-primary/30" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
          </svg>
        )}
      </motion.div>
    ))}
  </div>
);

export default function Referral({ initialData }) {
  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState(false);

  return (
    <Layout initialData={initialData}>
      <Container>
        <div className="max-w-4xl mx-auto text-center md:py-24">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              Partner With WymBee and
              <span className="text-primary block mt-2">Earn While You Grow</span>
            </h1>
            <p className="text-lg md:text-xl text-gray-600 mb-8">
              Join our partner program and earn 25% commission for 6 months on every business you refer. 
              Help others transform their WhatsApp commerce while growing your income.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Link
                to="/referral"
                className="bg-primary hover:bg-primary-hover text-white px-8 py-3 rounded-lg font-medium transition-colors duration-200"
              >
                Become a Partner
              </Link>
              <Link
                to="/pricing"
                className="bg-gray-50 hover:bg-gray-100 text-gray-900 px-8 py-3 rounded-lg font-medium transition-colors duration-200"
              >
                View Pricing Plans
              </Link>
            </div>
          </motion.div>
        </div>
      </Container>

      <div className="bg-gray-50 py-16">
        <Container>
          <h2 className="text-3xl font-bold text-center mb-12">
            Why Partner With Us?
          </h2>
          <PartnershipBenefits />
        </Container>
      </div>

      <Container>
        <div className="py-16">
          <h2 className="text-3xl font-bold text-center mb-4">
            Your Earning Potential
          </h2>
          <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
            From micro-businesses to enterprises, earn commission on every plan. 
            The more businesses you refer, the more you earn.
          </p>
          <CommissionExample />
          <p className="text-center text-sm text-gray-500 mt-4">
            *Commission rates shown are for illustration. Corporate plan commissions may vary based on final subscription value.
          </p>
        </div>
      </Container>

      <div className="bg-gray-50 py-16">
        <Container>
          <h2 className="text-3xl font-bold text-center mb-12">
            How It Works
          </h2>
          <HowItWorks />
        </Container>
      </div>

      <Container>
        <div className="py-16 text-center">
          <h2 className="text-3xl font-bold mb-6">
            Ready to Start Earning?
          </h2>
          <p className="text-gray-600 mb-8 max-w-2xl mx-auto">
            Join our partner program today and start earning commissions while helping businesses 
            transform their WhatsApp commerce experience.
          </p>
          <button
            onClick={() => setIsPartnerModalOpen(true)}
            className="inline-flex items-center gap-2 bg-primary hover:bg-primary-hover text-white px-8 py-3 rounded-lg font-medium transition-colors duration-200"
          >
            Become a Partner
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
          </button>
        </div>
      </Container>
      <PartnerModal 
        isOpen={isPartnerModalOpen} 
        onClose={() => setIsPartnerModalOpen(false)} 
      />
    </Layout>
  );
}